import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './DropdownContent.module.scss';
import Icon from '../Icon';

const AOrDiv = ({children, ...props}) => {
    if (!props.href) {
        delete props.href
        return <div {...props}>{children}</div>
    }

    return <a {...props}>{children}</a>
}
const DropdownContent = ({ columns, isActive }) => {
    return (
        <div className={classNames(
            styles[
            'DropdownContent'
            ], {
            [styles['DropdownContent--Active']]: isActive
        }
        )}>
            {columns &&
                columns.map((column, columnIndex) => {
                    return (
                        <nav key={columnIndex} className={classNames(
                            styles['DropdownContent__Nav']
                        )}>
                            {column.headerLink && (
                                <AOrDiv
                                    href={column.headerLink.href}
                                    className={
                                        styles['DropdownContent__Header']
                                    }>
                                    {column.headerLink.title}
                                    {column.headerLink.title && column.headerLink.href ? (
                                        <div
                                            className={
                                                styles['DropdownContent__Icon']
                                            }>
                                            <Icon
                                                type="chevronThin"
                                                size="medium"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                styles[
                                                'DropdownContent__NoHeader'
                                                ]
                                            }></div>
                                    )}
                                </AOrDiv>
                            )}
                            <ul className={styles['DropdownContent__List']}>
                                {column.links &&
                                    column.links.map((link, linkIndex) => {
                                        return (
                                            <li key={linkIndex}>
                                                <a
                                                    className={
                                                        styles[
                                                        'DropdownContent__Link'
                                                        ]
                                                    }
                                                    href={link.href}>
                                                    {link.title}
                                                </a>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </nav>
                    );
                })}
        </div>
    );
};

DropdownContent.propTypes = {
    columns: PropTypes.array,
};

DropdownContent.defaultProps = { columns: [] };

export default DropdownContent;
