// import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import classNames from 'classnames';
import styles from './Footer.module.scss';

const Footer = ({ links, social, copyright, searchPageUrl }) => {
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Container']}>
                <div className={styles['Footer__Content']}>
                    <div
                        className={classNames(
                            styles['Footer__Column'],
                            styles['Footer__Column--Logo'],
                            styles['Footer__Column--Desktop']
                        )}>
                        <a aria-label="Start page" href="/" className={styles['Footer__Logo']}>
                            <Icon type="nordRedBoxLarge" size="xxxxlarge" />
                        </a>
                    </div>
                    <div
                        className={classNames(
                            styles['Footer__Column'],
                            styles['Footer__Column--Links']
                        )}>
                        <ul
                            className={classNames(
                                styles['Footer__LinkList'],
                                styles['Footer__LinkList--Links']
                            )}>
                            {links.map((link, i) => (
                                <li
                                    className={styles['Footer__LinkListItem']}
                                    key={i}>
                                    <a
                                        href={link.href}
                                        className={styles['Footer__Link']}>
                                        {link.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div
                        className={classNames(
                            styles['Footer__Column'],
                            styles['Footer__Column--Social']
                        )}>
                        <ul
                            className={classNames(
                                styles['Footer__LinkList'],
                                styles['Footer__LinkList--Social']
                            )}>
                            {social.youtube && (
                                <li className={styles['Footer__LinkListItem']}>
                                    <a
                                        target={"_blank"}
                                        href={social.youtube.href}
                                        rel="noopener noreferrer"
                                        className={classNames(
                                            styles['Footer__SocialLink'],
                                            styles[
                                            'Footer__SocialLink--YouTube'
                                            ]
                                        )}>
                                        <div className={styles['Footer__SoMeIcon']}>
                                            <Icon type="youtube" size="large" />
                                        </div>
                                        {social.youtube.title}
                                    </a>
                                </li>
                            )}
                            {social.instagram && (
                                <li className={styles['Footer__LinkListItem']}>

                                    <a
                                        target={"_blank"}
                                        href={social.instagram.href}
                                        rel="noopener noreferrer"
                                        className={classNames(
                                            styles['Footer__SocialLink'],
                                            styles[
                                            'Footer__SocialLink--Instagram'
                                            ]
                                        )}>
                                        <div className={styles['Footer__SoMeIcon']}>
                                            <Icon type="instagram" size="large" />
                                        </div>
                                        {social.instagram.title}
                                    </a>
                                </li>
                            )}
                            {social.twitter && (
                                <li className={styles['Footer__LinkListItem']}>

                                    <a
                                        target={"_blank"}
                                        href={social.twitter.href}
                                        rel="noopener noreferrer"
                                        className={classNames(
                                            styles['Footer__SocialLink'],
                                            styles[
                                            'Footer__SocialLink--Twitter'
                                            ]
                                        )}>
                                        <div className={styles['Footer__SoMeIcon']}>
                                            <Icon type="twitterx" size="large" />
                                        </div>
                                        {social.twitter.title}
                                    </a>
                                </li>
                            )}

                            {social.facebook && (
                                <li className={styles['Footer__LinkListItem']}>
                                    <a
                                        target={"_blank"}
                                        href={social.facebook.href}
                                        rel="noopener noreferrer"
                                        className={classNames(
                                            styles['Footer__SocialLink'],
                                            styles[
                                            'Footer__SocialLink--Facebook'
                                            ]
                                        )}>
                                        <div className={styles['Footer__SoMeIcon']}>
                                            <Icon type="facebook" size="large" />
                                        </div>
                                        {social.facebook.title}
                                    </a>
                                </li>
                            )}
                        </ul>
                        {searchPageUrl && (
                            <div className={styles['Footer__SearchContainer']}>
                                <form
                                    action={searchPageUrl}
                                    className={styles['Footer__Search']}>
                                    <input
                                        type="search"
                                        name="q"
                                        className={
                                            styles['Footer__SearchField']
                                        }
                                    />
                                    <span
                                        className={
                                            styles['Footer__Placeholder']
                                        }>
                                        Search
                                    </span>
                                    <button
                                        type="submit"
                                        className={styles['Footer__Submit']}>
                                        <Icon type="search" size="large" />
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                    <div
                        className={classNames(
                            styles['Footer__Column'],
                            styles['Footer__Column--Logo'],
                            styles['Footer__Column--Mobile']
                        )}>
                        <a href="/" className={styles['Footer__Logo']}>
                            <Icon type="nordRedBoxLarge" size="xxxxlarge" />
                        </a>
                    </div>
                </div>

                <div className={styles['Footer__BottomRow']}>
                    <button id="csconsentlink" className={styles['Footer__CookieSettings']}>Cookie settings</button>
                    <p className={styles['Footer__Copyright']}>{copyright}</p>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {};

Footer.defaultProps = {
    social: {},
    links: [],
    copyright: '',
};

export default Footer;
