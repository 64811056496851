import React, { useState, useEffect } from 'react';

import styles from './Header.module.scss';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import PropTypes from 'prop-types';
import MobileHeader from '../MobileHeader/MobileHeader';
import DropdownContent from '../DropdownContent/DropdownContent';
import Headroom from 'react-headroom';

const Header = ({ menuItems, secondaryHeader }) => {
    const [pinned, setPin] = useState(true);

    const headerClasses = classNames(styles['Header'], {
        [styles['Header--Unpinned']]: !pinned,
    });

    const headerRef = React.useRef();
    const dropdownRef = React.useRef();
    const [activeIndex, setActiveindex] = useState(-1);

    const handleDropdown = (index) => {
        setActiveindex(activeIndex === index ? -1 : index);
    };

    useEffect(() => {
        const handleClick = (event) => {
            const clickingDropdown = dropdownRef.current && dropdownRef.current.contains(event.target);
            const clickingHeader = headerRef.current && headerRef.current.contains(event.target)

            if (activeIndex >= 0 && (!clickingHeader && !clickingDropdown)) {
                setActiveindex(-1);
            }
        };

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [activeIndex]);

    return (
        <>
            <div className={headerClasses}>
                <Headroom
                    onPin={() => { setPin(true) }}
                    onUnpin={() => { setPin(false) }}
                >
                    <header ref={headerRef} className={styles['Header__Wrap']}>
                        <div className={styles['Header__Container']}>
                            <a aria-label="Start page" href="/" className={styles['Header__Logo']}>
                                <Icon type="nordRedBoxMedium" size="xxxlarge" />
                            </a>
                            <nav className={styles['Header__Navigation']}>
                                <ul className={styles['Header__Menu']}>
                                    {menuItems &&
                                        menuItems.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {item.columns ? (
                                                    <li
                                                        className={
                                                            styles['Header__Dropdown']
                                                        }>
                                                        <button
                                                            className={classNames(styles["Header__DropdownButton"], {
                                                                [styles['Header__DropdownButton--Active']]: item.isActive
                                                            })}
                                                            onClick={() => {
                                                                handleDropdown(index);
                                                            }}>
                                                            {item.name}
                                                            <div
                                                                className={classNames(
                                                                    styles[
                                                                    'Header__Icon'
                                                                    ],
                                                                    {
                                                                        [styles[
                                                                            'Header__Icon--Active'
                                                                        ]]:
                                                                            activeIndex ===
                                                                            index,
                                                                    }
                                                                )}>
                                                                <Icon
                                                                    type="chevronThin"
                                                                    size="large"
                                                                />
                                                            </div>
                                                        </button>
                                                    </li>
                                                ) : (
                                                    <li
                                                        className={
                                                            styles['Header__MenuItem']
                                                        }>
                                                        <a
                                                            href={item.link.href}
                                                            className={classNames(styles['Header__MenuLink'], {
                                                                [styles['Header__MenuLink--Active']]: item.isActive
                                                            })}
                                                        >
                                                            {item.link.title}
                                                        </a>
                                                    </li>
                                                )}
                                            </React.Fragment>
                                        ))}
                                </ul>
                                <div className={styles['Header__Underline']}></div>
                            </nav>
                            <nav className={styles['Header__ContactNav']}>
                                <ul className={styles['Header__ContactMenu']}>
                                    {secondaryHeader.map((itm, index) => (
                                        <li key={index}>
                                            <a
                                                href={itm.href}
                                                className={classNames(styles["Header__ContactLink"], {
                                                    [styles['Header__ContactLink--Active']]: itm.isActive
                                                })
                                                }>
                                                {itm.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </header>
                    <div className={styles['Header__DropdownContainer']} ref={dropdownRef}>
                        {menuItems &&
                            menuItems.map((menuItem, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={classNames(
                                            styles['Header__DropdownContent'],
                                            {
                                                [styles[
                                                    'Header__DropdownContent--Active'
                                                ]]: activeIndex === index,
                                            }
                                        )}>
                                        <DropdownContent columns={menuItem.columns} isActive={activeIndex === index} />
                                    </div>
                                );
                            })}
                    </div>
                </Headroom>
            </div>
            <div className={styles['Header__MobileHeader']}>
                <MobileHeader menuItems={menuItems} secondaryHeader={secondaryHeader} />
            </div>
        </>
    );
};

Header.propTypes = {
    secondaryHeader: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            href: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            isActive: PropTypes.bool,
            href: PropTypes.string,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    headerLink: PropTypes.shape({
                        text: PropTypes.string,
                        href: PropTypes.string,
                    }),
                    links: PropTypes.arrayOf(
                        PropTypes.shape({
                            text: PropTypes.string,
                            href: PropTypes.string,
                        })
                    ),
                })
            ),
        })
    ),
};

Header.defaultProps = {
    secondaryHeader: [],
    menuItems: [],
};

export default Header;
