import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon/Icon';
import styles from './MobileHeader.module.scss';
import Headroom from 'react-headroom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const MobileHeader = ({ menuItems, secondaryHeader }) => {
    const [pinned, setPin] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(
        Array(menuItems.length).fill(false)
    );

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleAccordionIcon = (index) => {
        const newAccordionOpen = [...accordionOpen];
        newAccordionOpen[index] = !newAccordionOpen[index];
        setAccordionOpen(newAccordionOpen);
    };

    const classes = classNames(styles['MobileHeader'], {
        [styles['MobileHeader--Open']]: isOpen,
        [styles['MobileHeader--Unpinned']]: !pinned,
    });

    return (
        <Headroom
            disable={isOpen}
            onPin={() => { setPin(true) }}
            onUnpin={() => { setPin(false) }}
        >
            <div className={classes}>
                <a href="/" aria-label="Start page" className={styles['MobileHeader__Logo']}>
                    <Icon type="nordRedBoxSmall" size="xxlarge" />
                </a>
                <div className={styles['MobileHeader__MobileMenuWrapper']}>
                    {/* Hidden search bar */}
                    {/* <div className={styles['MobileHeader__SearchButton']}>
                    <SearchBar />
                    </div> */}
                    <div
                        className={styles['MobileHeader__Button']}
                        onClick={() => toggleMenu()}>
                        <div className={styles['MobileHeader__HamburgerMenu']}>
                            <span className={styles['MobileHeader__Line1']}></span>
                            <span className={styles['MobileHeader__Line2']}></span>
                            <span className={styles['MobileHeader__Line3']}></span>
                        </div>
                    </div>
                    <div className={styles['MobileHeader__MobileMenu']}>
                        <nav>
                            <ul className={styles['MobileHeader__MobileMenuItems']}>
                                {menuItems.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {item.columns ? (
                                                <li>
                                                <Accordion
                                                    className={
                                                        styles[
                                                        'MobileHeader__AccordionContainer'
                                                        ]
                                                    }
                                                    allowMultipleExpanded={true}
                                                    allowZeroExpanded={true}
                                                    onChange={() =>
                                                        handleAccordionIcon(i)
                                                    }>
                                                    <AccordionItem
                                                        className={
                                                            styles[
                                                            'MobileHeader__ListItem'
                                                            ]
                                                        }>
                                                        <AccordionItemHeading
                                                            className={
                                                                styles[
                                                                'MobileHeader__AccordionHeading'
                                                                ]
                                                            }>
                                                            <AccordionItemButton
                                                                className={
                                                                    styles[
                                                                    'MobileHeader__AccordionButton'
                                                                    ]
                                                                }>
                                                                {item.name}
                                                                <span
                                                                    className={`${styles.MobileHeader__ToggleButton
                                                                        } ${accordionOpen[
                                                                            i
                                                                        ]
                                                                            ? styles.active
                                                                            : ''
                                                                        }`}>
                                                                    <span className="sr-only">
                                                                        {accordionOpen
                                                                            ? 'Minus'
                                                                            : 'Plus'}
                                                                    </span>
                                                                </span>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <ul
                                                                className={
                                                                    styles[
                                                                    'MobileHeader__AccordionItemPanel'
                                                                    ]
                                                                }>
                                                                {item.columns.map(
                                                                    (column, j) => {
                                                                        const hasHeaderLink =
                                                                            column.headerLink &&
                                                                            column
                                                                                .headerLink
                                                                                .title;
                                                                        return (
                                                                            <React.Fragment
                                                                                key={`${i}-column-${j}`}>
                                                                                {hasHeaderLink && (
                                                                                    <li
                                                                                        className={
                                                                                            styles[
                                                                                            'MobileHeader__SubListItem'
                                                                                            ]
                                                                                        }>
                                                                                        <a
                                                                                            href={
                                                                                                column
                                                                                                    .headerLink
                                                                                                    .href
                                                                                            }
                                                                                            className={
                                                                                                styles[
                                                                                                'MobileHeader__MainLink'
                                                                                                ]
                                                                                            }>
                                                                                            {
                                                                                                column
                                                                                                    .headerLink
                                                                                                    .title
                                                                                            }
                                                                                            <div
                                                                                                className={
                                                                                                    styles[
                                                                                                    'MobileHeader__Icon'
                                                                                                    ]
                                                                                                }>
                                                                                                <Icon
                                                                                                    type="chevronThin"
                                                                                                    size="medium"
                                                                                                />
                                                                                            </div>
                                                                                        </a>
                                                                                    </li>
                                                                                )}
                                                                                {column.links &&
                                                                                    column.links.map(
                                                                                        (
                                                                                            link,
                                                                                            k
                                                                                        ) => {
                                                                                            return (
                                                                                                <li
                                                                                                    className={
                                                                                                        styles[
                                                                                                        'MobileHeader__SubListItem'
                                                                                                        ]
                                                                                                    }
                                                                                                    key={`${i}-link-${j}-${k}`}>
                                                                                                    <a
                                                                                                        href={
                                                                                                            link.href
                                                                                                        }
                                                                                                        className={
                                                                                                            styles[
                                                                                                            'MobileHeader__SubLink'
                                                                                                            ]
                                                                                                        }>
                                                                                                        {
                                                                                                            link.title
                                                                                                        }
                                                                                                    </a>
                                                                                                </li>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                                </li>
                                            ) : (
                                                <li
                                                    className={
                                                        styles[
                                                        'MobileHeader__ListItem'
                                                        ]
                                                    }
                                                    key={i}>
                                                    {item.link && (
                                                        <a
                                                            href={item.link.href}
                                                            className={
                                                                styles[
                                                                'MobileHeader__Link'
                                                                ]
                                                            }>
                                                            {item.link.title}
                                                        </a>
                                                    )}
                                                </li>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                            <div className={styles['MobileHeader__Contact']}>
                                {secondaryHeader.map((contact, i) => (
                                    <a
                                        href={contact.href}
                                        className={
                                            styles['MobileHeader__ContactLink']
                                        }
                                        key={i}>
                                        {contact.title}
                                    </a>
                                ))}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </Headroom>
    );
};

MobileHeader.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            columns: PropTypes.arrayOf(
                PropTypes.shape({
                    headerLink: PropTypes.shape({
                        href: PropTypes.string,
                        text: PropTypes.string,
                    }),
                    links: PropTypes.arrayOf(
                        PropTypes.shape({
                            href: PropTypes.string,
                            text: PropTypes.string,
                        })
                    ),
                })
            ),
            link: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ),
    secondaryHeader: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            isActive: PropTypes.bool,
        })
    ),
};

MobileHeader.defaultProps = {
    menuItems: [],
    secondaryHeader: [],
};

export default MobileHeader;
